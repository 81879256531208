import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js";
import * as s from "./index.module.scss";

export default function IndexPage({ data }) {
  return (
    <Layout title="CMake Sadness" className={s.container_flap}>
      <h1 className={s.title}>CMake Sadness</h1>
      <p className={s.text}>
        CMake makes me sad on a regular basis. Sometimes, CMake makes me angry, frustrated
        and disappointed. How could something like this become a de facto industry standard?
      </p>
      <p className={s.text}>
        Probably the only thing that's comparable in terms of spread and sadness is PHP.
        This page is analogue to <a href="http://phpsadness.com" target="_blank">phpsadness.com</a>.
        It's a collection of issues that makes my day-to-day interactions with CMake worse
        than they should be.
      </p>
      <div className={s.articles}>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div className={s.article_link} key={node.frontmatter.slug}>
            <span className={s.article_date}>{node.frontmatter.date}</span>
            <Link to={node.frontmatter.slug}>
              <span className={s.article_title}>{node.frontmatter.title}</span>
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            title
            date
          }
        }
      }
    }
  }
`;
